.hotel-check {
  .custom-control-label {
    &::before {
      top: 0;
    }
    &::after {
      top: 0;
    }
  }
}
