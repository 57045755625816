.fatwas-section {
  .fatwa-header {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
  }

  .fatwa-view {
    margin-bottom: 1rem;
    .fatwa-ul {
      transition: 0.2s ease-in-out;
      li {
        background: #fff;
        transition: 0.2s ease-in-out;
        &:hover {
          background: #efefef;
        }
      }
      h6 {
        transition: 0.2s ease-in-out;
      }
      &:hover {
        h6 {
          color: #656565;
        }
      }
    }
    .head {
      display: flex;
      min-height: 35px;
      .icon-box {
        background: #bda461;
        color: #fff;
        border-radius: 0 5px 5px 0;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .fatwa-text {
        display: flex;
        background: #324356;
        color: #fff;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.3rem;
        border-radius: 5px 0 0 5px;
        a,
        p {
          color: #fff;
        }
        a {
          text-decoration: underline !important;
          color: #bda461;
        }
      }
    }
    ul {
      li {
        margin: 0.5rem 1.8rem 0.5rem 0;
        box-shadow: 0px 2px 8px #c6c6c6;
        padding: 0.5rem;
        border-radius: 5px;
        h6 {
          color: #7e672a;
        }
        p {
          color: #656565;
        }
      }
    }
  } //fatwa-view
}

.fatwas-section-details {
  .title {
    color: #bda461;
    border-bottom: 1px solid #bda461;
  }
  .fatwas-content {
    .head {
      background: #bda461;
      color: #fff;
      width: max-content;
      padding: 0.3rem;
      font-size: 14px;
    }
    .text {
      padding: 0.3rem 0;
    }
  }
  .input-code {
    border: 1px solid #abb4bd4d;
    padding: 0.3rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    min-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e3e3e3;
    p {
      font-size: 20px;
      color: #324356;
    }
  }
}

.fatwas-see-more {
  .title {
    color: #bda461;
    border-bottom: 1px solid #bda461;
  }
  .fatwa-view {
    margin-bottom: 1rem;

    ul {
      li {
        margin: 0.75rem 0;
        box-shadow: none;
        padding: 0.5rem;
        border-radius: 5px;
        border: 1px solid #DEDEDE !important;
        background: #ffffff;
        h6 {
          color: #7e672a;
        }
        p {
          color: #656565;
        }
      }
    }
  } //fatwa-view
  // .fatwa-item {
  //   float: left;
  // }
}

//Fatwa Item Global Fatwa Section
.fatwa-item {
  background: url(../../../../assets/images/pilgrim-image/fatwas/fatwa-ground.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  img {
    width: 30px;
    height: 30px;
  }
  p {
    font-weight: 600;
    margin-top: 0.3rem;
  }
}
