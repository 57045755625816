@import "../helpers/variables";

.umrah-packages {
  // background: url(../image/umrah-package.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  .package-filter {
    width: 60%;
    margin: auto;
    .package-list {
      .card {
        border-radius: 5px;
        border: 1px solid #c6cbd0;
        overflow: hidden;

        .card-header {
          background-color: #fff;
          border-radius: 0;
          padding: 2px;
          max-height: 53px;
          border-bottom: 0;
          cursor: pointer;
          p {
            font-size: 12px;
            color: #d5d5d5;
          }
          span {
            font-size: 12px;
            color: #324356;
            font-weight: normal;
          }
          .fa-angle-down {
            color: #324356;
          }
        }
        .card-body {
          border-top: 1px solid #c6cbd0;
        }
      }

      .form-input {
        .card {
          margin-bottom: 1rem;
        }
        .full-input {
          display: flex;
          align-items: center;
          border-radius: 5px;
          .input-icon {
            padding-right: 0.3rem;
          }
        }
        .icon-card {
          float: left;
          margin: 0.5rem;
        }

        .collapse-content {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .room-collapse {
            display: flex;
            justify-content: space-around;
            margin-bottom: 1rem;
            span,
            i {
              width: 33.333%;
              text-align: center;
            }
            i {
              cursor: pointer;
              font-size: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .input_num {
              max-width: 31%;
              font-size: 11px;
              height: auto;
              padding: revert;
            }
          }
          .full-input {
            width: 70px;
          }
        }
      } //form-input
    }
    .search-button {
      background: $blueColor;
      border: none;
      height: 53px;
      display: flex;
      justify-content: center;
      padding: 0;
      cursor: pointer;
      button {
        border: none;
        background: transparent;
        color: #fff;
        cursor: pointer;
        width: 100%;
        height: 100%;
        outline: none;
        text-transform: uppercase;
      }
    }
  } //package-filter
  i {
    color: $grey;
  }
} //umrah-packages

.package-filter {
  .umrah-tabs {
    display: flex;
    justify-content: space-around;
    .nav-item {
      width: 49%;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
    }
    .nav-link {
      width: 100%;
      text-align: center;
      border-radius: 0;
      padding: 1rem 0rem;
      margin: 0;
    }
    .nav-link.active {
      position: relative;
      border: none;
      transition: all 0.3s ease-in-out;
      &::after {
        content: "";
        position: absolute;
        border-width: 0px 8px 8px 8px;
        border-style: solid;
        border-color: #aeaeae transparent #aeaeae transparent;
        bottom: 0;
        right: 50%;
      }
      h6 {
        color: #fff;
      }
    }
  }
  .fa-plane {
    transform: rotateZ(-45deg);
  }
}

.umrah-tabs .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $blueColor;
}

.umrah-tabs .nav-link.active p,
.umrah-tabs .nav-link.active i {
  color: $whiteColor !important;
  transition: all 0.3s ease-in-out;
}

.mybooking-input-wrapper {
  // change button style
  .btn-primary {
    min-width: 120px !important;
    height: 100%;
    background-color: $goldColor !important;
    outline: none !important;
    border-color: $goldColor;
    border-radius: 0;

    .fa-search {
      font-size: $font22;
    }
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none;
  }
  // end button style
  .form-group {
    // margin-bottom: 0px !important;
    position: relative;

    .form-control-custom-input {
      height: 55px !important;
      padding: 15px 15px 0px 15px;
      font-size: $fontText + 3;
      border-radius: 5px;
      background: $whiteColor;
    }

    .booking-label {
      position: absolute;
      top: 5px;
      right: 20px;
      font-size: $font11 + 1;
      color: #898989;
      margin: 0;
    }

    .fa-calendar {
      @extend %fa-icons-style;
    }

    .fa-suitcase {
      @extend %fa-icons-style;
    }

    .fa-id-card {
      @extend %fa-icons-style;
    }
    i {
      @extend %fa-icons-style;
    }
  }
}

%fa-icons-style {
  position: absolute;
  right: 1.2%;
  top: 45%;
  margin: 0px 5px 0px 5px;
  font-size: $font20;
  color: $grey;
 
}

.custom-form-group-select {
  margin-bottom: 15px !important;
}
