.send-complaint {
  max-width: 550px;
  margin: auto;

  .send-complaint-box {
    background: #fff;
    padding: 1rem;
    border: 1px solid #dedede;
    border-radius: 5px;
    text-align: right;
  }
  .head {
    border-bottom: 1px solid #bda461;
    padding-bottom: 0.4rem;
    font-weight: 600;
  }
}
.upload-complaint {
  background: #e3e3e3;
  border: 1px dashed #898989;
  color: #fff;
  font-size: 15px;
  padding: 0.3rem 1rem;
  margin: 0.25rem 0;
  img {
    width: 20px !important;
  }
  p {
    color: #324356;
    display: inline-block;
  }
}

.show-upload-screen {
  background: #e3e3e3;
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dedede;
  .text {
    &:first-child {
      color: #324356;
    }
  }
  .icon {
    color: #656565;
  }
}

.answer-complaint {
  background: #EFEFEF;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #E3E3E3;
}
