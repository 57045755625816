.certified-companies-section {
  .filter-companies {
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 1rem;
    min-height: 100%;
    height: 100vh;
    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      h6 {
        color: #656565;
        font-weight: 600;
      }
      p {
        color: #bda461;
      }
    }
    .certified-input-search {
      margin-bottom: 0.5rem;
      label {
        position: relative;
        width: 100%;
        input {
          width: 100%;
          border: navajowhite;
          box-shadow: 0px 3px 12px #00000029;
          padding: 0 2rem;
          font-size: 14px;
          height: 35px;
          outline: none;
        }
        i {
          color: #acacac;
          position: absolute;
          top: 12px;
          right: 6px;
          font-size: 15px;
        }
      }
    }
  }
  .certified-companies {
    h6 {
      color: #656565;
      font-weight: 600;
    }
    .certified-box {
      min-height: 340px;
      background: #fff;
      border: 1px solid #ececec;
      margin-bottom: 1rem;
      position: relative;
      .online-img {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;
      }

      .star {
        background: #f2af37;
        width: 40px;
        height: 50px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 5px 5px;
        margin-top: -16px;
        margin-bottom: 16px;
        position: absolute;
        left: 16px;
        top: 16px;
        i {
          color: #fff;
        }
      }
      .item {
        margin-top: -56px;
        position: relative;
        text-align: center;
        min-height: 260px;
        .img-box {
          width: 120px;
          height: 120px;
          background: #ffff;
          border-radius: 50%;
          overflow: hidden;
          margin: 0.5rem auto;
          box-shadow: 0px -4px 6px #00000029;
        }
        .certified-address {
          display: flex;
          justify-content: center;
          margin-bottom: 0.3rem;

          > p {
            background: #f3f3f3;
            border: 1px solid #dedede;
            border-radius: 5px;
            color: #85959d;
            padding: 3px 10px;
            margin: 5px;
          }
        }
      } //item
    }
    .active-link {
      border-bottom: 3px solid #f2af37;
    }
  }
}

.certified-companies-details {
  h3 {
    color: #324356;
    font-weight: 600;
  }
  padding: 1rem 0;
  .img-box {
    width: 150px;
    height: 150px;
    background: #ffff;
    border-radius: 50%;
    overflow: hidden;
    margin: 0.5rem auto;
    box-shadow: 0px -4px 6px #00000029;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .thumbnails {
    display: flex;
    justify-content: center;
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
  .certified-ul {
    li {
      display: flex;
      margin: 1rem 0 3rem 0;
      p {
        font-size: 15px;
        font-weight: 700;
        max-width: 70%;
        &:first-child {
          width: 20%;
          color: #bda461;
        }
        &:last-child {
          color: #324356;
        }
      }
      span {
        padding-left: 1rem;
        color: #324356;
        font-size: 15px;
        font-weight: 700;
      }
      .reserv-btn {
        background: #bda461;
        color: #fff;
        padding: 1rem;
        box-shadow: 0px 3px 12px #00000029;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: #2c2c2c;
        }
      }
    } //li
  }
  .star-box {
    text-align: center;
    width: 32%;
    .star {
      background: #f2af37;
      width: 44px;
      height: 55px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 0px 5px 5px;
      margin-top: -16px;
      margin-bottom: 16px;
      i {
        color: #fff;
      }
    }
  }
}
