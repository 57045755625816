.query-details {
  background: #f2f2f2;
  padding: 1.5rem 0;
  .head {
    border-bottom: 1px solid #bda461;
    padding-bottom: 0.5rem;
  }

  .itinerary-trip {
    padding: 1rem 1rem 0 1rem;
    background: #fff;
    border: 1px solid #dedede;
    min-height: 231px;
    border-radius: 5px;
  }

  .reservation-status {
    .icon-ul {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      > p {
        font-weight: 700;
      }
      .icon {
        border: 2px solid #00b437;
        width: 25px;
        height: 25px;
        line-height: 22px;
        border-radius: 50%;
        text-align: center;
        color: #00b437;
        margin-left: 0.3rem;
        position: relative;
        background: #fff;

        &::after {
          content: "";
          position: absolute;
          top: 23px;
          left: 10px;
          height: 100%;
          border: 1px dashed #dedede !important;
          z-index: 0;
        }
      }
    }
    .icon-ul:last-child .icon::after {
      border: none !important;
    }
  } //reservation-status
  .itinerary {
    min-height: 470px;
    .icon-ul {
      .icon {
        border: 1px dashed #00b437;
        width: 45px;
        height: 45px;
        line-height: 45px;
        margin-left: 0.8rem;
        position: relative;
        background: #fff;
        &::after {
          content: "";
          position: absolute;
          top: 44px;
          left: 21px;
          height: 100%;
          border: 1px dashed #737e8b !important;
          z-index: 0;
        }

        i {
          font-size: 18px;
        }
      }
    } //icon-ul
    .icon-ul:last-child .icon::after {
      border: none !important;
    }
  }
}
