// << slider section >> //
.transport-slider-container {
  width: 100%;
  padding: 5px;

  .slider-headers {
    width: 100%;
    padding: 5px 0px;
    margin: 5px 0px;

    h6 {
      color: $grey;
      font-weight: 700;
    }
    a {
      text-decoration: underline !important;
      color: $primaryColor;
      transition: all 0.3s ease-in-out;
    }
  }

  $sliderImageHeight: 400px;
  // << slider content wrapper >> //
  .slider-content-wrapper {
    width: 100%;
    min-height: $sliderImageHeight;
    max-height: $sliderImageHeight;
    // background-color: firebrick;
    #vertical-slider {
      img {
        object-fit: cover;
        width: 100%;
        max-height: $sliderImageHeight;
      }
    }
    .lSSlideOuter.vertical .lSAction > .lSNext {
      right: auto;
    }

    .vertical {
      padding-right: initial !important;
      padding-left: 255px !important;
    }
    .lSGallery {
      margin-left: initial !important;
      margin-right: 5px !important;
    }

    .lSSlideOuter.vertical .lSGallery {
      right: auto;
      left: 0 !important;
    }
  }
}

.hotel-facilities {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 20%;
      font-size: $fontText;
      color: $grey;
      margin-bottom: 0.3rem;
      .fa-check-circle {
        color: #354f5c;
      }
    }
  }
} //hotel-facilities

.payment-polices {
  .payment-policy {
    border: 1px solid #e8e8e8;
    background: #ebfff1;
    padding: 0.5rem 0;
    display: flex;
    min-height: 72px;
    li {
      font-size: $fontText;
    }
    i {
      width: 5%;
      color: #00b437;
      margin: 0.5rem;
    }
  }
  .cancellation-policy {
    border: 1px solid #e8e8e8;
    padding: 0.5rem 0;
    background: $whiteColor;
    display: flex;
    min-height: 72px;
    li {
      font-size: $fontText;
    }
    i {
      width: 5%;
      color: $rateColor;
      margin: 0.5rem;
    }
  }
} //payment-polices

.hotel-room-section {
  .hotel-room-count {
    .hotel-room-box {
      background: $whiteColor;
      padding: 0.5rem;
      border: 1px solid $borderColor;
      margin-bottom: 0.5rem;
      h6 {
        font-size: initial;
        color: $greyDarkColor;
        margin-bottom: 0.3rem;
        font-weight: 700;
      }
      li {
        color: #83878b;
        font-size: $fontText;
      }
      .free {
        color: $goldColor;
        font-weight: bold;
      }
      .old-disc {
        color: $redColor;
        text-decoration: line-through;
      }
      .new-disc {
        font-weight: bold;
      }
      .hotel-facility {
        select {
          background: $lightGreyColor;
          outline: none;
        }
        button {
          border-radius: 0;
          a {
            font-size: 1rem;
          }
          &:hover {
            transition: all 0.3s ease-in-out;
            background: $grey !important;
          }
        }
      } //hotel-facility
      .big-img {
        img {
          width: 250px;
          height: 120px;
          object-fit: cover;
        }
      } //big-img
      .thubmail-img {
        img {
          width: 125px;
          height: 60px;
          object-fit: cover;
        }
      } //thubmail-img
    } //hotel-room-box
  } //hotel-room-count
  .hotel-room-salary {
    padding: 1rem;
    text-align: center;
    .fixed-price {
      text-transform: uppercase;
      font-weight: bold;
      color: $greenColor;
    }
    .next-btn {
      background-color: $orangColor;
      color: $whiteColor;
      border: 0;
      border-radius: 0;
    }
  }
} //hotel-room-section

.similar-hotels {
  .card-img-top {
    width: 100%;
    height: 223px;
    object-fit: cover;
  }

  .total-price {
    position: absolute;
    top: 10px;
    background-color: $goldColor;
    padding: 0.5rem;
    left: 0;
    width: 30%;
    h6,
    p {
      color: #fff;
      font-size: 12px;
      text-align: center;
    }
    p {
      font-size: $fontText + 8;
      span {
        font-size: $fontText - 3;
        position: relative;
        top: -7px;
      }
    }
  }
  .card-body {
    h5 {
      font-size: $fontText + 5;
      margin-bottom: 0.3rem;
    }
    > div {
      display: flex;
      justify-content: space-between;
      width: 80%;
      span {
        position: relative;
        padding-left: 1.5rem;
        &:after {
          content: "|";
          position: absolute;
          left: 0;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      } //span
    }
    a.btn {
      &:hover {
        color: $whiteColor !important;
        background: #4a6572 !important;
      }
    }
  } //card-body
} //similar-hotels
