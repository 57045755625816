.plus-round {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background: #bca556;
  color: #fff;
}

.reservation-section .reservation-box .bank-account-filter {
  justify-content: unset;
  background: #f2f2f2;
  > .main-input-search {
    margin: inherit;
    padding: 0.5rem;
  }
}

.bank-transaction {
  display: flex;
  padding: 2rem;
  justify-content: center;
  background-color: #fff;
  > div {
    padding: 0.8rem;
    background: #f2f2f2;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #c6cbd0;
    border-radius: 5px;
    width: 25%;
    margin: 0 0.3rem;
  }
}
