.full-input {
  padding: 8px 10px;
  border: 1px solid #c6cbd0;
  width: auto;
  margin-bottom: 1rem;
  max-height: 53px;
  background: #fff;
  label {
    display: block;
    font-size: $fontText;
    color: $paragraphColor;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
  }
  input {
    outline: none;
    border: none;
    display: block;
    font-size: $fontText;
    padding: 0;
    height: auto;
    outline: none;
    &:focus {
      outline: none;
      box-shadow: 0 !important;
    }
  }
  select {
    width: 100%;
    border: none;
    font-size: 12px;
    color: $placeholderColor;
    outline: none;
    cursor: pointer;
  }
  .form-control {
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
  input::placeholder {
    color: $placeholderColor;
  }
  .eye-icon {
    float: right;
    margin-top: -18px;
    cursor: pointer;
    i {
      font-size: $fontText;
      color: $placeholderColor;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #343a40;
      }
    }
  }
} //full-input

$primary-color: #1777fb; // Change color here. C'mon, try it!
$text-color: mix(#000, $primary-color, 64%);

// RadioInput
.radio-input {
  margin: 1.5rem auto;
  label {
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        // background-color: mix(#fff, $primary-color, 84%);
        &:before {
          box-shadow: inset 0 0 0 0.2975em $primary-color;
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      // &:hover {
      //     background-color: mix(#fff, $primary-color, 84%);
      // }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #fff;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        // margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em $primary-color;
      }
    }
  }

  .icon-radio {
    margin: 0 0.5em;
    font-size: $fontText;
  }
  .text-radio {
    margin-top: 15px;
    p {
      &:first-child {
        font-weight: 600;
      }
      &:last-child {
        color: $placeholderColor;
      }
    }
  }
}

//security code page
.form-tab-four {
  display: flex;
  justify-content: center;
  .full-input {
    width: 40px;
    margin: 0.5rem;
    input {
      text-align: center;
    }
  } //full-input
} //form-tab-four

//input search
.main-input-search {
  label {
    position: relative;
    input {
      border: 1px solid #e8e8e8;
      height: 35px;
      font-size: 11px;
      padding: 0.7rem 0.5rem;
      width: auto;
      outline: none;
    }
    i {
      font-size: 11px;
      color: $placeholderColor;
      position: absolute;
      top: 14px;
      left: 5px;
    }
  }
} //main-input-search

//input date
.main-input-date {
  label {
    position: relative;
    input {
      border: 1px solid #e8e8e8;
      height: 20px;
      font-size: 11px;
      padding: 0.7rem 0.5rem;
      width: 100px;
    }
    i {
      font-size: 11px;
      color: $placeholderColor;
      position: absolute;
      top: 9px;
      right: 5px;
    }
  }
} //main-input-date

//select box
.posting-select {
  width: 100px;
  height: 25px;
  margin-bottom: 0.5rem;
  select {
    border: 1px solid #e8e8e8;
    font-size: 11px;
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    cursor: pointer;
    color: $placeholderColor;
  }
}

.global-input {
  height: 50px !important;
  padding: 15px 40px 0px 10px;
  color: #324356;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #dee2e6 !important;
  outline: none;
}
