header {
  position: relative;
  .upper-bar {
    background: #324356;
    padding: 0;
  }

  .pilgrims-nav {
    box-shadow: 0px 3px 6px #00000029;
    .nav-link {
      color: #324356 !important;
      font-size: 14px;
    }
    .plig-drop {
      background: #bca556;
      border-radius: 5px;
      img {
        width: 25px;
      }
      .nav-link {
        color: #fff !important;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        i {
          padding: 0 0.3rem;
        }
      }
      .dropdown-item.active,
      .dropdown-item:active {
        color: #656565;
      }
    }
  }
}

.header-citizen {
  .pilgrim-header {
    // background: url(../images/pilgrim-image/header/back-img.png) no-repeat fixed
    //   100% 100%;
    background-size: cover !important;
    min-height: 96vh;
        padding: 1rem 0;
    background-attachment: fixed;
    @media (min-width: 1920px) and (max-width: 5000px) {
      display: flex !important;
      align-items: center;
    }
    .pilgrim-dashboard,
    .pilgrim-login {
      min-height: 435px;
      .head {
        // padding: 1rem 0;
        p {
          font-size: 18px;
          line-height: 1.54;
        }
      }
    }
    .pilgrim-dashboard {
      .col-md-4 {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
      .item-link {
        transition: all 0.3s ease-in-out;
        &:hover {
          .item {
            p {
              color: #bca556;
            }
          }
        }
      }
      .item {
        min-height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #e3e3e3;
        margin-bottom: 0.5rem;
        position: relative;
        img {
          width: 70px;
          height: 70px;
        }
        p,
        a {
          padding-top: 0.8rem;
          font-size: 14px;
          font-weight: bold;
          color: #324356;
          transition: all 0.3s ease-in-out;
        }
        .warn-icon {
          position: absolute;
          left: 5px;
          top: 5px;
          font-size: 14px;
          color: #e30000;
        }
      }
    } //pilgrim-dashboard
    .pilgrim-login {
      position: relative;
      background-color: #fff;
      padding: 1rem 2rem;
      .main-input-search {
        margin-bottom: 0.5rem;
        label {
          margin-bottom: 0;
          color: #898989;
          font-size: 14px;
        }
        input {
          border: none;
          border-bottom: 1px solid #abb4bd4d;
          border-radius: 0;
          padding: 0;
          outline: none;
          box-shadow: none;
          color: #324356;
          font-weight: bold;
          text-align: right;
        }
        select {
          border: none !important;
          border-bottom: 1px solid #abb4bd4d !important;
          font-size: 14px;
          outline: none;
          padding: 0;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .resend-input {
        display: flex;
        padding: 4rem 0;
        input {
          text-align: center;
          margin: 0 1rem;
          width: 20%;
        }
      }
      .submit-btn {
        height: 40px !important;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem auto;
        width: 100%;
        color: #fff;
        border: none;
        // font-weight: 700;
      }
    } //pilgrim-login
  } //pilgrim-header

  .shade-img {
    position: absolute;
    bottom: 0px;
  }
}

.statistics-section {
  background-color: #fff;
  padding: 3rem 0;
  .head {
    border-bottom: 1px solid #bda461;
    padding-bottom: 0.7rem;
  }

  /*
  .umrah-statistics {
    display: flex;
    flex-wrap: wrap;

    .statistics {
      width: 48%;
      background: #f8f8f8;
      box-shadow: 0px 3px 6px #00000029;
      margin: 0.5rem 0.2rem;
      padding: 0.5rem;
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #ebebeb;
      }
      .title {
        color: #656565;
        padding: 0.2rem 0;
      }
      i {
        color: #bda461;
        font-size: 50px;
        padding: 1rem 0;
      }
      .count {
        font-size: 20px;
        font-weight: bold;
        color: #324356;
        padding: 0.2rem 0;
      }
    }
  } //umrah-statistics
  */

  .carousel-inner {
    margin-top: 5px;
    border-radius: 5px;
  }

  .carousel-item {
    img {
      min-height: 340px;
    }
  }
  .carousel {
    .fas {
      font-size: 22px;
      color: #bca556;
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: -39px;
    z-index: 1;
    color: #000;
    transition: opacity 0.15s ease;
    display: block;
    width: auto;
    padding: 3px;
    opacity: 1;
    bottom: auto;
  }
  .carousel-control-next {
    left: 28px;
    right: auto;
  }

  .umrah-box {
    background: #f8f8f8;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
  } //umrah-box

  .prayer-timing {
    .prayer-box {
      .prayer-ul {
        display: flex;
        justify-content: center;
        margin-bottom: 0.5rem;
        li {
          padding: 0.8rem 1.5rem;
          background: #f2f2f2;
          border-radius: 10px;
          margin: 0 0.3rem;
          position: relative;
          color: #bda461;
          font-weight: 700;
          &::before {
            content: ":";
            position: absolute;
            left: -6px;
            font-weight: bold;
          }
          &:last-child {
            &::before {
              content: "";
            }
          }
        }
      } //prayer-ul

      .prayer-time {
        li {
          display: flex;
          margin: 0.3rem 0;
          .pray-name,
          .pray-time {
            // background: #ebebeb;
            background: #f8f8f8;
            padding: 0.5rem;
            font-weight: 700;
            text-align: center;
          }
          &:nth-child(odd) {
            .pray-name,
            .pray-time {
              background: #ebebeb;
            }
          }
          .pray-name {
            width: 25%;
            border-radius: 5px;
          }
          .pray-time {
            width: 75%;
            margin: 0 0.4rem;
            border-radius: 5px;
          }
        }
      }
    }
    select {
      outline: none;
    }
  } //prayer-timing
}

//News Section
.news-section {
  background-color: #f6f8fb;
  padding: 5rem 0 3rem 0;
  position: relative;
  .shade-news {
    position: absolute;
    top: 0;
  }
  .head {
    border-bottom: 1px solid #bda461;
    padding-bottom: 0.7rem;
  }

  .haram-video {
    .video-box {
   
      background-size: cover;
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #ff001d;
      i {
        cursor: pointer;
      }
    }
  }
} //News Section

.pilgrims-footer {
  background: #2c2c2c;
  padding: 0.3rem 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  p {
    color: #fff;
    font-size: 12px;
  }
}
