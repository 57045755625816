.lecture-section {
  .lecture-box {
    padding: 0 10rem;
    .lecture {
      border: 1px solid #d6d6d6;

      .head {
        display: flex;
        justify-content: space-between;
        background: #f2f2f2;
        padding: 1rem 0.5rem;
        border-bottom: 1px solid #d6d6d6;
      }
    }
  }
}
