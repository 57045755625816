.rites-header {
  background: #656565;

  .rites-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rites-link {
      padding: 0.8rem 1rem;
      margin-left: 1rem;
    }
    .active {
      background: #bda461;
    }
    a {
      color: #fff;
    }
    .nav{
      .nav-item a{
        font-size: 14px;
      }
    }
  }
}

.rites-section {
  background: #f2f2f2;
  padding: 1rem 0;
  .title {
    color: #bda461;
    border-bottom: 1px solid #bda461;
    p {
      color: #bda461;
    }
  }

  .rites-data {
    padding: 0 0.5rem;
    h6 {
      font-weight: 700;
    }
    a {
      color: #bca556;
    }
  }
  .img-box {
    width: 180px;
    height: 140px;
    border-radius: 5px;
    overflow: hidden;
    margin: 1rem 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
