.reservation-section {
  .reservation-box {
    .inventory-tab {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem;
      background: $whiteColor;
      .inventory-item {
        width: 20%;
        margin-bottom: 1.5rem;
        > h6 {
          margin-bottom: 0.3rem;
          font-weight: 700;
        }
        .copy-btn {
          background: #bca556;
          color: #fff;
          padding: 0.1rem 1rem;
          font-size: 12px;
          cursor: pointer;
          border-radius: 0;
          margin: 0 0.2rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: $grey;
          }
        }
      }
    } //inventory-tab
    .reservation-data {
      .inventory-item {
        width: 33.3%;
      }
    }
    .all-room-inventory {
      padding: 0.5rem;
      background: #f2f2f2;
      h6 {
        color: #656565;
      }
      .room-inv {
        display: flex;
        justify-content: space-around;
        background: #fff;
        padding: 0.5rem;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
        margin-bottom: 0.5rem;
        div {
          > h6 {
            margin-bottom: 0.3rem;
            font-weight: 700;
            color: #000;
          }
          a {
            > p {
              text-decoration: underline;
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
}
