@import "../helpers/variables";

/*Start Market Place Wrapper*/
.market-place-wrapper {
  background-color: $grey;
  .mybooking-search-wrapper {
    min-height: 70px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .mybooking-grid-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto;
      gap: 5px 5px;
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
      @media (max-width: 575px) {
        display: block;
        text-align: center;
      }
    }

    .mybooking-input-wrapper {
      .form-group {
        margin-bottom: 0 !important;
      }

      .fa-plane {
        transform: rotateZ(-45deg);
      }
      &:first-child {
        flex: auto;
      }
      &:nth-child(4) {
        flex: auto;
      }
      .submit-btn {
        border-radius: 5px;
        min-width: 80px !important;
        font-size: 12px;
        font-weight: 700;
      }
      @media (max-width: 575px) {
        margin-bottom: 1rem;
      }
    }
  }
}
/*End Market Place Wrapper*/

/*Start Tabs Section*/
.flight-tabs-section {
  background-color: $lightGreyColor;
  font-size: $fontText;
  h6 {
    color: $grey;
  }
  .select-flight {
    .select-flight-title {
      h6 {
        font-size: initial;
      }
      p {
        font-size: 11.5px;
      }
    } //select-flight-title

    .select-flight-ul {
      display: flex;
      justify-content: flex-end;
      li {
        background: #f0eeef;
        border: 1px solid #c6cbd0;
        border-radius: 5px;
        padding: 0.7rem 3rem;
        margin: 0 0.3rem;
      }
      .active {
        background: $orangColor;
        h6 {
          color: $whiteColor;
        }
      }
    } //select-flight-ul

    .sorting {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 1rem 0 0.5rem 0;
      @media (max-width: 575px) {
        display: block;
        text-align: center;
      }
      span {
        font-size: $fontText + 2;
        text-transform: uppercase;
        padding-right: 0.5rem;
        color: $paragraphColor;
      }
      select,
      label {
        color: #686d71;
        border: 1px solid #eee;
        margin: 0;
        padding: 0.2rem;
        i {
          cursor: pointer;
          padding: 0 1.5rem 0 0;
        }
        @media (max-width: 575px) {
          margin-bottom: 1rem;
        }
      }
    }

    .info-box {
      background-color: #fffdf8;
      padding: 0.5rem;
      border: 1px solid #eee;
      .fa-info-circle {
        color: $rateColor;
      }
      .date-info {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem;
        text-align: right;
        @media (max-width: 575px) {
          display: block;
          text-align: center;
        }
        .active {
          background: #63b54d;
          h6,
          p {
            color: $whiteColor;
          }
        }
        > div {
          border: 1px solid #eee;
          padding: 0.5rem 1rem;
          background-color: $whiteColor;
          width: 100%;
          margin: 0 0.5rem;
        }
      }
    }
  }

  .flight-filter-box {
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid $borderColor;
    overflow: auto;
    .flight-filter-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $borderColor;
      padding: 0.5rem;
      .filter-reset {
        a {
          padding: 0;
          i {
            color: $btnLinkColor;
          }
        }
      }
    } //flight-filter-title
    .airlines {
      padding: 0.5rem 0.5rem 0 0.5rem;
      text-align: right;
      @media (max-width: 575px) {
        text-align: center;
      }

      h6 {
        font-size: $fontText;
      }
      &:last-child {
        padding-bottom: 0.5rem;
      }
      .airlines-checkbox {
        .custom-control {
          display: flex;
          align-items: center;
          @media (max-width: 575px) {
            display: block;
          }
        }
        .custom-control-label {
          padding-top: 0.2rem;
          color: $grey;
          cursor: pointer;
          i {
            color: $rateColor;
          }
          .far.fa-star {
            color: #b9bdc2;
          }
        }
      }
    } //air-line
  } //flight-filter-box

  //flight-invoice-tab-wrapper
  .flight-invice-tab-wrraper {
    .b2c-invoice-wrapper {
      .show-button {
        display: flex;
        justify-content: center;
        button {
          outline: none;
          box-shadow: none;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: $goldColor;
            color: $whiteColor;
          }
        }
      }
      .hotel-detail-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 575px) {
          display: block;
          text-align: center;
        }

        .img-box {
          min-height: 160px;
          width: 200px;
          height: 160px;
          @media (max-width: 575px) {
            width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .total-price-box {
          width: 180px;
          background: #f9f9f9;
          border: 1px solid #eee;
          height: 120px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: auto;
          @media (max-width: 575px) {
            width: 100%;
          }
          > h6 {
            color: #d5d5d5;
          }
        }
        .details-button {
          width: 180px;
          color: $whiteColor;
          margin-top: 0.2rem;
          border-radius: 0;
          font-weight: 700;
          font-size: $fontText;
          @media (max-width: 575px) {
            width: 100%;
          }
          &:hover {
            color: $whiteColor;
            background: #4a6572 !important;
          }
          a {
            &:hover {
              color: $whiteColor !important;
            }
          }
        }
      } //hotel-detail-section
    }
  } //flight-invice-tab-wrraper

  /* hotel detail section */
  .hotel-detail-section {
    margin: 10px 0px;
    padding: 5px;
    width: 100%;
    min-height: 170px;
    border: 1px solid $greyMediumColor;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(170px, auto);
    background-color: $whiteColor;
    .hotel-detail-item {
      padding: 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      @media (max-width: 575px) {
        width: 100%;
        display: block;
      }

      .hotel-header {
        text-align: right;
        .text-decor {
          text-decoration: underline !important;
          color: $primaryColor;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: $goldColor !important;
          }
        }
        h6 {
          font-weight: 700;
        }
        .hotel-star-rate {
          margin: 1px 0;
          .fa-star {
            color: $rateColor;
          }
        }
        .hotel-text {
          max-height: 90px;
          overflow: hidden;
        }
      }
    }
  }
} //filter-tabs-section
/*End Tabs Section*/
