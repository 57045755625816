$placeholderColor: #acacac;
$goldColor: #bca556;
$greyColor: #9c9c9c;
$greyDarkColor: #3c3c3c;
$greyMediumColor: #c6cbd0;
$lightGreyColor: #f9f9f9;
$grey: #656565;
$paragraphColor: #324356;
$whiteColor: #ffffff;
$blueColor: $goldColor;
$orangColor: #dc7542;
$greenBlackColor: $goldColor;
$borderColor: #e8e8e8;
$greenColor: #009a2f;
$redColor: #dc7542;
$primaryColor: #0070af;
$btnLinkColor: #c8c8c8;
$rateColor: #ffc649;

/* float items*/
$float-left: left;
$float-right: right;
$text-right: right;
$text-left: left;

/* global font size for fast use */
$fontText: 12px;
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font22: 20px;
$font24: 24px;
$font25: 25px;
$font26: 26px;
$font28: 28px;
$font30: 30px;
$font36: 36px;

.text-gray {
  color: $grey;
}

.font-8 {
  font-size: 8px;
}
