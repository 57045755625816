/*Start Market Place Wrapper*/
.transportation-wrapper {
  .mybooking-search-wrapper {
    .mybooking-grid-container {
      gap: 5px 0px;
    }
    .mybooking-input-wrapper {
      &:first-child {
        flex: inherit !important;
      }
      &:nth-child(4) {
        flex: inherit !important;
      }
      .submit-btn {
        min-width: 100% !important;
      }
    }
  }
}

/*End Market Place Wrapper*/
