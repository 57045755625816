@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/css/bootstrap-rtl.min.css";
@import "assets/css/homepages.scss";
* {
  font-family: "Cairo", sans-serif;
}

.ngx-gallery {
  display: inline-block;
  margin-bottom: 20px;
}
.mat-h6,
.mat-typography h6 {
  // font: inherit !important;
  font: 400 calc(24px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  font-family: "Cairo", sans-serif;
}
.mat-body,
.mat-body-1,
.mat-typography,
.mat-h5,
.mat-typography h5 {
  font: inherit !important;
}
[dir="rtl"] .text-right {
  text-align: left !important;
}

.header-citizen {
  position: relative;
  .pilgrim-header {
    background: url("./assets/images/pilgrim-image/header/back-img.png")
      no-repeat fixed 100% 100%;
  }
}
header .pilgrims-nav .nav-link.active {
  // border-bottom: 1px solid #bca556 !important;
  color: #bca556 !important ;
  &::before {
    content: " ";
    width: 100%;
    height: 3px;
    background-color: #bca556;
    position: absolute;
    bottom: -11px;
    right: 0;
  }
}
.nav-item {
  margin: auto !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
select {
  appearance: auto !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f2f2f2 !important;
}

a {
  cursor: pointer !important;
}
.nav-item {
  position: relative;
}
.mat-body p,
.mat-body-1 p,
.mat-typography p,
.mat-h6,
.mat-typography h6 {
  margin: 0;
}
.swal2-popup {
  padding: 0 !important;
}
.mat-dialog-title {
  font-family: "Cairo", sans-serif !important;
}
.suffix_icon {
  font-size: 20px !important;
  color: #dbdbdb !important;
  right: auto !important;
  left: 0px !important;
  margin: 0px 5px 0px 5px !important;
}
.allErros {
  direction: ltr;
  .swal2-popup.swal2-toast {
    flex-direction: column !important;
  }
  .swal2-content {
    white-space: pre-line !important;
    font-size: 12px !important;
  }
  .swal2-html-container {
    margin: 8px !important;
  }
  .swal2-title {
    margin: 8px !important;
  }
  .swal2-x-mark {
    .swal2-x-mark-line-left {
      font-size: 16px !important;
    }
    .swal2-x-mark-line-right {
      font-size: 16px !important;
    }
  }
}

.fix_color_title {
  * {
    color: #fff !important;
  }
}
.navbar {
  padding: 0 !important;
}

.drag-scroll-content {
  overflow: hidden !important;
}
// fix peter UI issues
.header-citizen
  .pilgrim-header
  .pilgrim-dashboard
  .item-link.Hover-disable:hover
  .item
  p {
  color: #a2a2a2 !important;
}

.pilgrim-dashboard .item p,
.pilgrim-dashboard .item a {
  padding-top: 0.8rem;
  font-weight: bold;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.pilgrim-dashboard .item-link.Hover-disable .item p,
.pilgrim-dashboard .item-link.Hover-disable a {
  padding-top: 0.8rem;
  font-weight: bold;
  color: #a2a2a2;
  transition: all 0.3s ease-in-out;
}

.item-link .item {
  background: #fff !important;
}
.item-link.Hover-disable .item {
  cursor: not-allowed !important;
  background: #e3e3e3 !important;
}
.fix_bottom_title {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 15px;
}

.header-citizen .pilgrim-header {
  display: flex !important;
  align-items: center !important;
}
.gallery_btn {
  position: absolute;
  left: 0;
  top: 0;
  button {
    background: transparent !important;
    border: none !important;
    i {
      color: #bda461 !important;
      font-size: 22px !important;
    }
  }
  button:disabled {
    opacity: 0.4;
  }
}

.toggle-pass-btn {
  color: #324356;
  position: absolute;
  top: 40%;
  left: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

@media (max-width: 425px) and (min-width: 320px) {
  .certified-companies-section {
    .filter-companies {
      margin-top: 10px;
      margin-bottom: 20px;
      height: 220px !important;
    }
  }

  header .pilgrims-nav .nav-link.active {
    &::before {
      position: absolute;
      bottom: 3px;
    }
  }
  .mobile-none {
    display: none;
  }
}
.aclass {
  width: 200px;
  margin: auto;
  img {
    width: 100%;
  }
}

.section_padding__sm {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section_padding__md {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.check_touched {
  &:not(:has(.mat-checkbox-checked)) {
    .mat-checkbox-frame {
      border-color: red !important;
    }
  }
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-1rem {
  font-size: 1rem !important;
  @media screen and (max-width: 575px) {
    font-size: 0.9rem !important;
  }
}

.fs-md-rem {
  font-size: 0.9rem !important;
  @media screen and (max-width: 575px) {
    font-size: 0.85rem !important;
  }
}

.fs-sm-rem {
  font-size: 0.85rem !important;
  @media screen and (max-width: 575px) {
    font-size: 0.8rem !important;
  }
}

.fs-xs-rem {
  font-size: 0.8rem !important;
  @media screen and (max-width: 575px) {
    font-size: 0.75rem !important;
  }
}

.icon-fix {
  line-height: 0;
  vertical-align: middle;
  display: inline-block;
}
