.live-lecture-section {
  background: #f2f2f2;
  padding: 1rem 0;
  .leactures {
    margin-bottom: 1rem;
    .title {
      color: #bda461;
      border-bottom: 1px solid #bda461;
    }
    .live-lecture-box {
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      .img-box {
        img {
          width: 100%;
          height: 100%;
        }
      }
      .live-content {
        background: #fff;
        padding: 0.5rem;
        h6 {
          color: #bca556;
          font-size: 14px;
        }
      }
    }
  }
}

.my-lecture {
  padding: 1rem 0;
  .my-lecture-box {
    border: 1px solid #d6d6d6;
    margin: 1rem 0;
    .head {
      background: #f2f2f2;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      flex-direction: column;
      h6 {
        color: #656565;
      }
      .time {
        display: flex;
        padding: 1rem;
        > div {
          margin: 0 0.5rem;
          background: #f2f2f2;
          color: #bda461;
          width: 80px;
          height: 70px;
          text-align: center;
          border-radius: 5px;
          overflow: hidden;
          .count {
            font-size: 30px;
            color: #bca556;
            font-weight: bold;
          }
        }
      }
    }
  }
}
