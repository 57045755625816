.medical-section {
  .mybooking-input-wrapper .form-group .form-control-custom-input {
    border: 1px solid #dee2e6 !important;
  }
  .custom-control-label {
    cursor: pointer;
    &::after {
      right: 0 !important;
    }
    &::before {
      right: 0 !important;
    }
  }
} //medical-section

.modal-dialog {
  .mybooking-input-wrapper .form-group .form-control-custom-input {
    border: 1px solid #dee2e6 !important;
  }
}

.medical-result {
  border: 1px solid #d6d6d6;
  background-color: #f2f2f2;
  min-height: 30vh;

  .no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 30vh;
    color: #585858;
  }
}

.pointer {
  cursor: pointer !important;
}

.submit-btn {
  min-width: 120px !important;
  background-color: #bca556 !important;
  outline: none !important;
  border-color: #bca556;
  height: 50px !important;
  border-radius: 5px !important;
}

.pre-reservation {
  .pcr-data {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0.5rem;
    min-height: 54px;
  }
}

.input-upload {
  label {
    width: 100%;
    cursor: pointer;
    margin-bottom: 0;
    .img-box {
      border: 2px dashed #c2cdda;
      position: relative;

      .shape {
        width: 120px;
        height: 120px;
        margin: 3px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 45px;
          margin: 0.1rem auto;
        }
      } //shape
    }
  }
  input {
    display: none;
  }
} //input-upload
