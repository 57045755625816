.my-travel-section {
  .head {
    border-bottom: 1px solid #bda461;
    padding-bottom: 0.7rem;
    margin-bottom: 0.8rem;
    color: #bda461;
  }
  padding: 3rem 0;
  background: #f2f2f2;
  .my-travel {
    padding: 0.5rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dedede;
    border-radius: 5px;
    .trip {
      display: flex;
      align-items: center;
      .img-box {
        width: 85px;
        height: 85px;
        border: 1px solid #e3e3e3;
        border-radius: 50%;
        overflow: hidden;
        padding: 0.5rem;
        img {
          width: 100%;
          height: 100%;
        }
      } //img-box
      .data {
        margin-right: 1rem;
      }
    }
  }
}

.no-trips {
  text-align: center;
  i {
    color: #898989;
  }
  p {
    font-size: 14px;
    color: #898989;
  }
}
