* {
  box-sizing: border-box;
}
/*Main Font*/
@font-face {
  font-family: mainFont;
  src: url("https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap");
}

body {
  background: $lightGreyColor;
  text-align: $text-right;

  font: {
    family: "Cairo", sans-serif;
    size: 16px;
  }
}

ul,
ol {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.m-auto {
  width: 100%;
  margin: auto;
}

a {
  text-decoration: none !important;
  color: $paragraphColor;
}

.text-decor {
  text-decoration: underline !important;
  color: $primaryColor;
  transition: all 0.3s ease-in-out;
}

.text-color {
  color: $paragraphColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  font-size: $fontText;
  margin: 0;
  color: $paragraphColor;
  line-height: initial;
}
a,
span {
  font-size: $fontText;
}
a:hover {
  color: $goldColor;
}
.px-6 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.blue-color {
  color: #324356 !important;
}
.black-color {
  color: #324356;
}
.bg-grey {
  background-color: $grey !important;
}
.text-underline {
  text-decoration: underline !important;
}
.w-20 {
  width: 20%;
}

.orange-color {
  color: $orangColor !important;
}
.gold-color {
  color: $goldColor;
}
.gold-color-bg {
  background-color: $goldColor;
}
.text-dark {
  color: $grey !important;
}
.orange-bg-color {
  background-color: $orangColor !important;
}
.blue-bg-color {
  background-color: $blueColor !important;
}

.blue-main-color {
  color: $blueColor !important;
}

.main-color {
  color: #bca556 !important;
}

.text-black {
  color: #000;
}

input::-webkit-input-placeholder {
  color: $placeholderColor !important;
}

.form-control {
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 117, 66, 0.25);
    border-color: $goldColor;
  }
}

.outline-none {
  outline: none !important;
}

.white-color {
  color: #fff;
}

.text-bold {
  font-weight: bold;
}

.text-success {
  color: $greenColor;
}

.text-blue {
  color: $blueColor;
}

.no-bg {
  background-color: transparent !important;
}

.form-group {
  margin-bottom: 0.5rem;
}

.whiteColor {
  color: #fff !important;
}

.info-color {
  color: #898989 !important;
}

.fa-star,
.fa-info-circle {
  color: $rateColor;
}

.dropdown-item.active,
.dropdown-item:active {
  background: inherit;
}

select {
  cursor: pointer;
}

.cursor {
  cursor: pointer !important;
}

.btn {
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 165, 86, 0.25) !important;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $goldColor;
  background-color: $goldColor;
}

/*Start noUiSlider*/
.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.noUi-connect {
  background: $grey !important;
}
.noUi-horizontal {
  height: 12px !important;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none !important;
}
.noUi-touch-area {
  cursor: pointer !important;
}
.noUi-target,
.noUi-target * {
  outline: none !important;
}
/*End noUiSlider*/

.details-button {
  transition: all 0.3s ease-in-out;
  &:hover {
    background: $grey !important;
  }
}

button:disabled,
button[disabled] {
  cursor: no-drop;
}

.text-decoration-underline {
  text-decoration: underline !important;
}
.btn-success {
  background: #63b54d !important;
}

.main-input {
  border: 1px solid #abb4bd4d;
  padding: 0.3rem;
  border-radius: 5px;
  color: #898989;
  margin-bottom: 0.5rem;
  background: #fff;
  label {
    margin-bottom: 0;
    font-size: 14px;
  }
  input {
    border: none;
    display: block;
    outline: none;
  }
}
.main-select {
  display: flex;
  flex-direction: column;
  border: 1px solid #abb4bd4d;
  padding: 0.3rem;
  border-radius: 5px;
  color: #898989;
  margin-bottom: 0.5rem;
  background: #fff;
  label {
    margin: 0;
    font-size: 14px;
  }
  select {
    border: none;
    outline: none;
    font-size: 14px;
  }
}
textarea {
  resize: inherit;
  border: 1px solid #abb4bd4d !important;
}

.submit-button {
  min-height: 40px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: 100%;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  background: #bda461;
  cursor: pointer;
  outline: none;
}
.initial-dir {
  direction: initial !important;
}
