* {
  box-sizing: border-box;
}

// .container{
//     margin: auto;
//     padding-left: 15px;
//     padding-right: 15px;
//     position: relative;
//     @media #{$minSmall} {
//         width: 750px;
//     }
//     @media #{$minMedium} {
//         width: 970px;
//     }
//     @media #{$minLarge} {
//         width: 1170px;
//     }

// }

.clearfix {
  clear: both;
}
