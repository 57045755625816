.dashboard-companies {
  h1,
  h2,
  h3,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
  }
  .statistics {
    .main-tabs .main-nav .nav-item {
      margin-inline-start: 0;
    }
  }
  .revenue-statistics {
    h1,
    h2,
    h3,
    h3,
    h4,
    h5,
    h6 {
      color: #324356;
    }
    p {
      color: #686869;
      font-size: 12px;
    }

    .cards {
      .desc {
        h6 {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 30px;
            height: 3px;
            bottom: -10px;
            left: 0;
            [dir="rtl"] & {
              left: auto;
              right: 0;
            }
          }
        }
        // .count {
        //   //   font-size: $text-title4;
        //   font-weight: 700;
        //   color: $dark-blue;
        //   padding-top: 0.3rem;
        // }
        span {
          color: #acacac;
          //   font-size: $text-caption;
          font-weight: normal;
        }
      }
      .booking-text {
        color: #686869;
      }
      .hotels {
        border-bottom: 3px solid #24aefb;
        .desc {
          h6 {
            color: #24aefb;
            font-weight: 700;
            position: relative;
            &:after {
              background: #24aefb;
            }
          }
        }
      }

      .transportation {
        border-bottom: 3px solid #f68d33;
        .desc {
          > h6 {
            color: #f68d33;
            font-weight: 700;
            &:after {
              background: #f68d33;
            }
          }
        }
      }
      .total {
        border-bottom: 3px solid #00b34c;
        .desc {
          h6 {
            color: #00b34c;
            font-weight: 700;
            &:after {
              background: #00b34c;
            }
          }
        }
      }
    }
  }

  .activity {
    min-height: 100vh;
    font-size: 12px;

    .title {
      padding: 0.5rem 0;
      color: #354f5c;
      > h2 {
        color: #5a5a5a;
      }
      .text {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 40px;
          height: 2px;
          bottom: -7px;
          left: 0;
          background: #24aefb;
          [dir="rtl"] & {
            left: auto;
            right: 0;
          }
        }
        [dir="rtl"] & {
          padding-top: 0.1rem;
        }
      }
    }
    .date {
      text-align: center;
      p {
        background: #f4f4f4;
        display: inline-block;
        padding: 0.3rem 1rem;
        border-radius: 5px;
        color: #acacac;
        margin: 1rem 0;
      }
    }

    .main {
      position: relative;
      .time,
      p {
        color: #5a5a5a;
      }
      h2 {
        color: #24aefb !important;
      }
    }
    .tes {
      // position:relative;
      &::after {
        content: "";
        position: absolute;
        top: 35px;
        left: 44px;
        height: 90%;
        border: 1px dashed #e9e9ef !important;
        z-index: 0;
        [dir="rtl"] & {
          left: auto;
          right: 42.5px;
        }
      }
    }

    .main:last-child .tes::after {
      border: none !important;
    }
  }

  .filter-month {
    width: 130px;
    padding: 0 5px;
    .my-2 {
      margin: 0 !important;
    }
    .control-field__body {
      padding: 0.5rem;
    }
  }

  .count-box {
    .title {
      color: #5a5a5a;
      position: relative;
      margin-bottom: 1.5rem;
      font-weight: 700;
      &:after {
        content: "";
        position: absolute;
        width: 40px;
        height: 2px;
        bottom: -7px;
        left: 0;
        background: #bca556;
        [dir="rtl"] & {
          left: auto;
          right: 0;
        }
      }
    }
    .number {
      font-size: 30px;
      font-weight: 700;
      color: #5a5a5a;
    }
    .img-box {
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
  }
  .text {
    position: relative;
    color: #5a5a5a;
    &:after {
      content: "";
      position: absolute;
      width: 40px;
      height: 2px;
      bottom: -7px;
      left: 0;
      background: #bca556;
      [dir="rtl"] & {
        left: auto;
        right: 0;
      }
    }
    [dir="rtl"] & {
      padding-top: 0.1rem;
    }
  }
}

.activeClass {
  background: #313b4c;
  color: #fff;
}

.live-lectures {
  .lecture {
    padding: 0.5rem;
    background: #f1eddd;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: #000;
      &:first-child {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
