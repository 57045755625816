.reservation-section {
  background: #fafafa;
  .reservation-box {
    border: 1px solid #eee;
    margin: 1rem 0;
    .reservation-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f2f2f2;
      padding: 0.5rem;
      h6 {
        color: #656565;
      }
      .reserv-tabs {
        .nav-item {
          margin-right:0 !important;
          .nav-link {
            background: #d6d6d6;
            transition: all 0.3s ease-in-out;
            padding: 0.5rem 4rem;
            position: relative;
          }
        }
      }
      .nav-link.active h6 {
        color: #fff !important;
      }
      .nav-link.active {
        &::after {
          content: "";
          position: absolute;
          border-width: 0px 5px 5px 5px;
          border-style: solid;
          border-color: #aeaeae transparent #fff transparent;
          bottom: 0;
          right: 50%;
        }
      }
    } //reservation-header
    .reservation-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.5rem 0 0.5rem;
      background: #fff;
      > .main-input-search {
        width: 19%;
        margin: auto;
        label {
          width: 100%;
          input {
            width: 100%;
            border-radius: 5px;
          }
          i {
            font-size: 15px;
            top: 12px;
            color: #d6d6d6;
          }
        }
      }
      select {
        border: 1px solid #e8e8e8;
        height: 35px;
        font-size: 11px;
        padding: 0 0.5rem;
        outline: none;
        margin-top: 0 !important;
        color: $placeholderColor;
        border-radius: 5px;
        overflow: hidden !important;
      }
      .mybooking-input-wrapper,
      .filter-reset {
        width: 10%;
        text-align: center;
        margin-bottom: 0.5rem;
      }
      .mybooking-input-wrapper {
        .submit-btn {
          background: $goldColor;
          padding: 0.3rem 2rem;
          color: #fff;
          border-radius: 0.25rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            background: #656565;
          }
        }
      }
    } //reservation-filter
    .reservation-table {
      font-size: $fontText;
      th {
        font-weight: normal;
      }
      .progress {
        height: 1.5rem;
        background: #d6d6d6;
      }
    } //reservation-table
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background: #f2f2f2;
  }
  .pagination {
    display: flex;
    flex-flow: row-reverse;
    span,
    .page-link {
      padding: 0.5rem;
      line-height: 1;
      color: #656565;
      font-weight: bold;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: $goldColor;
        color: $whiteColor;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(188, 165, 86, 0.25);
      }
    }
  }
  .table td,
  .table th {
    vertical-align: unset;
  }
} //reservation-section

.umrah-tabs .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #bca556 !important;
}
